import {useInView} from 'react-cool-inview';
import {Marqy} from 'marqy';
import {useGid} from '~/lib/utils';

import type {Product} from '@shopify/hydrogen/storefront-api-types';
import ProductCard from '~/components/product/Card';

const Marquee = ({data}) => {
  const module = data.modules;
  const speed = data.speed;
  const reverse = data.reverse;
  const pausable = data.pausable;

  const {observe, inView} = useInView({
    unobserveOnEnter: true,
    threshold: 0.1,
  });

  return (
    <div className="stabMarquee">
      <div ref={observe} className=" marquee-section">
        <Marqy
          speed={speed}
          direction={reverse ? 'right' : 'left'}
          pauseOnHover={pausable}
        >
          <div className="marquee--item">
            {module.map((item, key) => {
              const productGid = item?.productWithVariant.gid;
              const productVariantGid = item?.productWithVariant.variantGid;
              const storefrontProduct = useGid<Product>(productGid);

              return (
                <div key={key} className="marquee--product">
                  <ProductCard
                    storefrontProduct={storefrontProduct}
                    variantGid={productVariantGid}
                  />
                </div>
              );
            })}
          </div>
        </Marqy>
      </div>
    </div>
  );
};

export default Marquee;
