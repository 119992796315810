import CalloutModule from '~/components/modules/Callout';
import CallToActionModule from '~/components/modules/CallToAction';
import CollectionModule from '~/components/modules/Collection';
import ImageModule from '~/components/modules/Image';
import InstagramModule from '~/components/modules/Instagram';
import ProductModule from '~/components/modules/Product';
import Marquee from '~/components/modules/Marquee';
import Content from './Content';

import type {SanityModule} from '~/lib/sanity';

type Props = {
  imageAspectClassName?: string;
  module: SanityModule;
};

export default function Module({imageAspectClassName, module}: Props) {
  switch (module._type) {
    case 'module.callout':
      return <CalloutModule module={module} />;
    case 'module.callToAction':
      return <CallToActionModule module={module} />;
    case 'module.collection':
      return <CollectionModule module={module} />;
    case 'module.image':
      return <ImageModule module={module} />;
    case 'module.instagram':
      return <InstagramModule module={module} />;
    case 'module.product':
      return (
        <ProductModule
          imageAspectClassName={imageAspectClassName}
          module={module}
        />
      );

    case 'module.marquee':
      return <Marquee data={module} />;

    case 'module.content':
      return <Content data={module} />;

    default:
      return null;
  }
}
