import clsx from 'clsx';
import PortableText from '~/components/portableText/PortableText';

const Content = ({data}) => {
  let bgClass = '';

  if (data.colorTheme !== null && data.colorTheme !== undefined) {
    bgClass = data.colorTheme.background;
  }

  return (
    <div className="contentModule" style={{backgroundColor: bgClass}}>
      <PortableText
        blocks={data.body}
        centered
        className={clsx(
          'mx-auto px-4 pb-18 pt-18', //
          'md:px-8',
        )}
      />
    </div>
  );
};

export default Content;
